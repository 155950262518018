// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$primary-palette: (
    50: #e0ebf1,
    100: #b3cedd,
    200: #80aec7,
    300: #4d8db0,
    400: #26749f,
    500: #005c8e,
    600: #005486,
    700: #004a7b,
    800: #004171,
    900: #00305f,
    A100: #90beff,
    A200: #5da0ff,
    A400: #2a83ff,
    A700: #1074ff,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$accent-palette: (
    50: #e4eff7,
    100: #bbd6eb,
    200: #8ebbde,
    300: #609fd0,
    400: #3e8bc6,
    500: #1c76bc,
    600: #196eb6,
    700: #1463ad,
    800: #1159a5,
    900: #094697,
    A100: #c4dbff,
    A200: #91bbff,
    A400: #5e9bff,
    A700: #458bff,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

/* For use in src/lib/core/theming/_palette.scss */
$warn-palette: (
    50: #fbe5e6,
    100: #f5bec0,
    200: #ee9396,
    300: #e7686c,
    400: #e1474d,
    500: #dc272d,
    600: #d82328,
    700: #d31d22,
    800: #ce171c,
    900: #c50e11,
    A100: #fff1f2,
    A200: #ffbebf,
    A400: #ff8b8d,
    A700: #ff7273,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$web-app-primary: mat.m2-define-palette($primary-palette);
$web-app-accent: mat.m2-define-palette($accent-palette, A200, A100, A400);
$web-app-warn: mat.m2-define-palette($warn-palette);

// $custom-typography: mat-typography-config($font-family: "Roboto",
//         $headline: mat-typography-level(32px, 48px, 700),
//         $body-1: mat-typography-level(16px, 24px, 500),
//     );
// @include mat.core($custom-typography);

$web-app-light-theme: mat.m2-define-light-theme((color: (primary: $web-app-primary,
                accent: $web-app-accent,
                warn: $web-app-warn,
            ),
            typography: mat.m2-define-typography-config($font-family: "Roboto",
            ),
            density: 0,
        ));

$web-app-dark-theme: mat.m2-define-dark-theme((color: (primary: $web-app-primary,
                accent: $web-app-accent,
                warn: $web-app-warn,
            ),
            typography: mat.m2-define-typography-config(),
        ));

// variables
//common
$brand-primary: #008cd7;
$brand-secondary: #005c8e;
$brand-tertiary: #004265;

$brand-green: #00943d;
$brand-light-green: #47b273;
$brand-light-green-1: #85cca2;
$brand-orange: #ee8c19;
$brand-yellow: #ffd000;
$brand-red: #dc272d;
$brand-blue: #0766f6;
$brand-gray: #7e7e7e;

$brand-green-shade: #e5f4eb;
$brand-light-green-shade: #e5f4eb;
$brand-light-green-1-shade: #f3faf6;
$brand-orange-shade: #fdf3e8;
$brand-red-shade: #fbe9ea;
$brancd-gray-shade: #f2f2f2;

//light-theme
$primary-background-light: #efefef;
$secondary-background-light: #ffffff;
$tertiary-background-light: #c1c1c1;
$primary-text-light: #3c3c3c;
$secondary-text-light: #7e7e7e;
$highlight-text-light: #008cd7;

//dark-theme
$primary-background-dark: #0e0e0e;
$secondary-background-dark: #1e1e1e;
$tertiary-background-dark: #c1c1c1;
$primary-text-dark: #ffffff;
$secondary-text-dark: #c1c1c1;
$highlight-text-dark: #008cd7;

:root {
    --info: #{$brand-secondary};
    --success: #{$brand-green};
    --failure: #{$brand-red};
    --remainder: #{$brand-orange};
    --warning: #{$brand-yellow};
    --error: #{$brand-gray};

    --brand-bg: #{$brand-secondary};
    --very-good: #{$brand-green};
    --good: #{$brand-light-green};
    --fair: #{$brand-light-green-1};
    --poor: #{$brand-orange};
    --very-poor: #{$brand-red};
    --unknown: #{$brand-gray};

    --very-good-shade: #{$brand-green-shade};
    --good-shade: #{$brand-light-green-shade};
    --fair-shade: #{$brand-light-green-1-shade};
    --poor-shade: #{$brand-orange-shade};
    --very-poor-shade: #{$brand-red-shade};
    --unknown-shade: #{$brancd-gray-shade};

    --white-text: #ffffff;
}

// Light mode
.light {
    --primary-background: #{$primary-background-light};
    --secondary-background: #{$secondary-background-light};
    --tertiary-background: #{$tertiary-background-light};
    --primary-text: #{$primary-text-light};
    --secondary-text: #{$secondary-text-light};
    --highlight-text: #{$highlight-text-light};

    @include mat.all-component-themes($web-app-light-theme);

    --mat-option-selected-state-label-text-color: #fff;
    --mat-select-panel-background-color: #c1c1c1;
    --mat-option-selected-state-layer-color: #005c8e;

    // --mdc-filled-text-field-container-color: transparent;
    // --mat-form-field-state-layer-color: transparent;
    // --mat-form-field-container-text-line-height: 16px;

    // --mdc-filled-text-field-active-indicator-color: #c1c1c1;
    // --mdc-filled-text-field-disabled-active-indicator-color: #c1c1c1;
    // --mdc-filled-text-field-hover-active-indicator-color: #c1c1c1;
    // --mdc-filled-text-field-error-active-indicator-color: #dc272d;
    // --mdc-filled-text-field-error-focus-active-indicator-color: #dc272d;
}

// Dark mode
.dark {
    --primary-background: #{$primary-background-dark};
    --secondary-background: #{$secondary-background-dark};
    --tertiary-background: #{$tertiary-background-dark};
    --primary-text: #{$primary-text-dark};
    --secondary-text: #{$secondary-text-dark};
    --highlight-text: #{$highlight-text-dark};

    @include mat.all-component-themes($web-app-dark-theme);

    --mat-option-selected-state-label-text-color: #1e1e1e;
    --mat-select-panel-background-color: #c1c1c1;
    --mat-option-selected-state-layer-color: #005c8e;
}