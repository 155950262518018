/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

/* basic layout */
html,
body {
    height: 100%;
    // overflow: hidden;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    background-color: var(--secondary-background);
    color: var(--primary-text);
}

.primary {
    background-color: var(--primary-background);
    padding: 25px 20px 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 42px;
    color: var(--secondary-text);

    h3.title-text {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: var(--primary-text);
    }
}

.secondary {
    background-color: var(--secondary-background);
    padding: 25px 20px 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 42px;
    color: var(--secondary-text);

    h3.title-text {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: var(--primary-text);
    }
}

/* 
** Select dropdown **
*/
.mat-mdc-select {
    color: var(--primary-text);
    // font-size: 12px;
    // line-height: 12px;
    cursor: pointer;
    border-bottom: 1px solid var(--primary-text);

    .mat-mdc-select-value {
        margin-right: 10px;

        // .mat-mdc-select-value-text {
        //     color: var(--primary-text);
        //     font-size: 12px;
        // }
    }

    .mat-mdc-select-arrow {
        color: var(--primary-text);
    }

    &[aria-expanded="true"] {
        border-bottom: 1px solid var(--highlight-text);

        .mat-mdc-select-value {
            .mat-mdc-select-value-text {
                color: var(--highlight-text);
            }
        }

        .mat-mdc-select-arrow {
            color: var(--highlight-text);
        }
    }
}

.mat-mdc-select-panel {
    padding: 0 !important;
    box-shadow: none !important;

    .mat-mdc-option {
        font-size: 14px;
        min-height: 32px !important;
        height: 32px !important;
    }

    .mat-mdc-option:not(.mat-mdc-option-multiple) .mat-pseudo-checkbox {
        display: none !important;
    }

    .mat-mdc-optgroup {
        .mat-mdc-option .mat-pseudo-checkbox {
            display: block !important;
        }
    }
}

/* 
** Select dropdown - end **
*/

/* 
** Card ** 
*/
.mat-mdc-card {
    box-shadow: none !important;
    border-radius: 0 !important;
    background-color: var(--secondary-background) !important;
    padding: 0;
    min-height: 250px;
    float: left;
    margin-bottom: 6px;

    .mat-mdc-card-title {
        color: var(--highlight-text);
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .card-details-icon {
        color: var(--highlight-text);
        cursor: pointer;
    }

    .span-card-link {
        color: var(--secondary-text);
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;

        &:hover {
            color: var(--highlight-text);
            text-decoration: underline;
        }
    }

    .mat-mdc-select {
        font-size: 12px;
        line-height: 12px;
    }

    .mat-mdc-card-content {
        .hr {
            color: #c1c1c1;
            border-width: 2px;
            width: 85%;
            margin: 0 30px;
        }

        .div-card-count {
            font-size: 55px;
            line-height: 60px;
            color: var(--primary-text);
            text-align: center;

            &.div-card-sub-count {
                font-size: 45px;
                line-height: 45px;
                margin-bottom: 0;
            }
        }

        .card-icon {
            position: relative;
        }

        .span-card-icons {
            vertical-align: text-bottom;
        }

        .div-card-label {
            color: var(--primary-text);
            font-size: 16px;
            text-align: center;

            &.div-card-sub-label {
                font-size: 12px;
                line-height: 1.2;
            }
        }

        .card-count-value {
            margin-left: 5px;
        }

        table {
            th {
                font-size: 12px;
                color: var(--secondary-text);
                border-color: var(--tertiary-background);
            }

            td {
                font-size: 12px;
                color: var(--secondary-text);
                border-color: var(--tertiary-background);
            }
        }
    }
}

/* 
** Card - end **
*/

/* 
** Checkbox ** 
*/
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-bottom-left-radius: 6px;
    // height: 20px;
    // width: 20px;
    background-color: #005c8e !important;
    border-color: #005c8e !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: #005c8e !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__mixedmark {
    border-color: white !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-bottom-left-radius: 6px;
    // height: 20px;
    // width: 20px;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
    color: white !important
}

/* 
** Checkbox - end **
*/

/* 
** Expansion panel **
*/
.mat-expansion-panel {
    box-shadow: none !important;

    .mat-expansion-panel-header {
        padding: 0 15px;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 10px;
        height: 48px;
        // background: var(--primary-background) !important;

        .mat-expansion-panel-header-title {
            color: var(--highlight-text);
        }
    }
}

.mat-expansion-panel:not(.mat-expanded) {
    background: transparent !important;
}

// .mat-expansion-panel.mat-expanded {
//     background: var(--primary-background) !important;
// }

// .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
//     background: var(--primary-background) !important;
// }

.mat-expansion-panel-spacing {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
}



/** NEW **/
.mat-accordion {
    .mat-expansion-panel {
        box-shadow: none !important;
        background-color: var(--secondary-background);

        .mat-expansion-panel-header {
            background-color: var(--secondary-background);

            &:hover {
                background-color: var(--secondary-background);
            }
        }
    }

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
        background-color: var(--secondary-background);
    }
}

/* 
** Expansion panel - end **
*/

/* 
** Tooltip Customization **
*/
.mat-mdc-tooltip {
    --mdc-plain-tooltip-container-color: var(--brand-bg) !important;
    --mdc-plain-tooltip-supporting-text-color: white;
    --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
    --mdc-plain-tooltip-supporting-text-size: 16px;
    --mdc-plain-tooltip-supporting-text-weight: 400;
    --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
    --mdc-plain-tooltip-container-border-bottom-right-radius: 25px;
    --mdc-plain-tooltip-supporting-text-line-height: 30px;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
    border-radius: 15px !important;
}

.mat-mdc-tooltip-trigger {
    cursor: default !important;
}

.app-tooltip {
    background-color: var(--brand-bg) !important;
    border-radius: 0px 0px 0px 20px;
    position: relative;
    min-width: 80px;
    min-height: 35px;
    list-style: 35px;
    text-align: center;
    font-size: 16px;
    left: 80%;
    top: 40%;
}

/* 
** Tooltip Customization - end ** 
*/

/* 
** Scroll customization **
*/
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    // margin-right: 10px;
    // height: 12px;
}

::-webkit-scrollbar-track {
    background: #efefef;
}

::-webkit-scrollbar-thumb {
    background: #7e7e7e;
}

::-webkit-scrollbar-thumb:hover {
    background: #3c3c3c;
}


// ::-webkit-scrollbar-track {
//     background: #f1f1f1;
//     /* Background color of the scrollbar track */
// }

// ::-webkit-scrollbar-thumb {
//     background: #888;
//     /* Background color of the scrollbar thumb */
//     border-radius: 3px;
//     /* Rounded corners for the scrollbar thumb */
// }

// ::-webkit-scrollbar-thumb:hover {
//     background: #555;
//     /* Background color of the scrollbar thumb on hover */
// }

/* Customize scrollbar for Firefox */
// * {
//   scrollbar-width: thin; /* Thin scrollbar */
//   scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
// }

/* Optional: Customize scrollbar for specific elements */
.custom-scrollbar {
    overflow-x: auto;
    overflow-y: auto;
    /* Enable horizontal scrolling */
}

.custom-scrollbar::-webkit-scrollbar {
    height: 12px;
    /* Height of the horizontal scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background color of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    /* Background color of the scrollbar thumb */
    border-radius: 4px;
    /* Rounded corners for the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Background color of the scrollbar thumb on hover */
}

.custom-scrollbar {
    scrollbar-width: thin;
    /* Thin scrollbar for Firefox */
    scrollbar-color: #888 #f1f1f1;
    /* Thumb color and track color for Firefox */
}

/* 
** Scroll customization - end **
*/

/*
** Badge **
*/
::ng-deep.mat-badge-medium .mat-badge-content {
    width: 27px;
    height: 27px;
    line-height: 27px;
}

/*
** Badge - end**
*/

/* 
** Form **
*/
.mdc-text-field {
    padding: 0px !important;
    background-color: transparent !important;
}

.mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay,
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
}

.mdc-line-ripple::before {
    border-bottom-color: var(--tertiary-background) !important;
}

.mat-mdc-form-field {
    line-height: 16px !important;
}

.mat-mdc-form-field-flex {
    height: 30px;
}

.mat-mdc-form-field-infix {
    padding: 5px 0px !important;
    min-height: 0 !important;
}

::ng-deep.mat-mdc-form-field-subscript-wrapper {
    // display: none !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-bottom-left-radius: 0 !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border-bottom-right-radius: 0 !important;
}

.mdc-notched-outline__leading {
    border-left: none !important;
    border-top: none !important;
}

.mdc-notched-outline__trailing {
    border-right: none !important;
    border-top: none !important;
}

/* 
** Form - end **
*/

/*
** Button **
*/
.mdc-button {
    min-width: 120px !important;
    border-radius: 20px !important;

    &.mat-mdc-outlined-button {
        color: var(--secondary-text) !important;
    }

    &.mat-stroked-button {
        border-radius: 0px !important;
    }
}

/*
** Button - end **
*/

/*
** Table **
*/
.mat-mdc-table {
    background-color: transparent !important;

    thead {
        background: transparent !important;
    }

    .mat-column-action {
        width: 150px;
    }

    // .mat-mdc-cell.newRow {
    //     background: white !important;
    // }
}

.table> :not(caption)>*>* {
    background-color: transparent !important;
}

tr.table-element-row:not(.table-expanded-row):hover {
    background: transparent !important;
}

/*
** Table - end**
*/


::ng-deep.mdc-list-item.mdc-list-item--with-one-line {
    height: 48px !important;
}

.mdc-list-item {
    height: 48px !important;

}

/*
** Paginator **
*/
.mat-mdc-paginator {
    background-color: transparent !important;

    &.hide {
        display: none;
    }
}

.mat-mdc-paginator {
::ng-deep.mat-mdc-paginator-range-actions{
    .mat-mdc-paginator-range-label{
        display: none !important;
    }
    .mat-mdc-paginator-page-size {
        display: none;
    }
    .mat-custom-page-number {
        display: block;
        margin: 0 4px;
        padding: 4px 12px;
        min-width: auto;
        border-radius: 4px;
        cursor: pointer;
    }
    
    .mat-mdc-paginator-navigation-first,
    .mat-mdc-paginator-navigation-previous,
    .mat-mdc-paginator-navigation-next,
    .mat-mdc-paginator-navigation-last {
        width: 40px !important;
        height: 40px !important;
        min-width: 40px !important;
        padding: 8px !important;
        background-color: transparent;
        border: 1px solid var(--tertiary-background) !important;
        border-radius: 5px !important;
        color: var(--secondary-text) !important;
        margin: 0 5px;
    }
    
    .mat-custom-page-number.mdc-button.mdc-button--outlined {
        width: 40px;
        height: 40px;
        min-width: 40px !important;
        background-color: transparent;
        border: 1px solid var(--tertiary-background) !important;
        border-radius: 5px !important;
        color: var(--secondary-text) !important;
    
        &.mdc-button--raised {
            background-color: var(--brand-bg) !important;
            color: #fff !important;
        }
    }
}
}
// ::ng-deep .mat-mdc-paginator-page-size {
//     display: none;
// }

// ::ng-deep.mat-mdc-paginator-range-label {
//     display: none;
// }

// ::ng-deep .mat-custom-page-number {
//     display: block;
//     margin: 0 4px;
//     padding: 4px 12px;
//     min-width: auto;
//     border-radius: 4px;
//     cursor: pointer;
// }

// ::ng-deep.mat-mdc-paginator-navigation-first,
// ::ng-deep.mat-mdc-paginator-navigation-previous,
// ::ng-deep.mat-mdc-paginator-navigation-next,
// ::ng-deep.mat-mdc-paginator-navigation-last {
//     width: 40px !important;
//     height: 40px !important;
//     min-width: 40px !important;
//     padding: 8px !important;
//     background-color: transparent;
//     border: 1px solid var(--tertiary-background) !important;
//     border-radius: 5px !important;
//     color: var(--secondary-text) !important;
//     margin: 0 5px;
// }

// ::ng-deep .mat-custom-page-number.mdc-button.mdc-button--outlined {
//     width: 40px;
//     height: 40px;
//     min-width: 40px !important;
//     background-color: transparent;
//     border: 1px solid var(--tertiary-background) !important;
//     border-radius: 5px !important;
//     color: var(--secondary-text) !important;

//     &.mdc-button--raised {
//         background-color: var(--brand-bg) !important;
//         color: #fff !important;
//     }
// }



/*
** Paginator - end **
*/

/*
** Dialog **
*/
.cdk-overlay-pane.mat-mdc-dialog-panel.slide-out-panel-dialog {
    width: 25% !important;
    height: 100vh !important;
    position: absolute !important;
    right: 0 !important;

    .mat-mdc-dialog-surface.mdc-dialog__surface {
        border-radius: 0 !important;
    }
}

/*
** Dialog - end **
*/


/* 
** Lib customization **
*/
/**
*** Highcahrts ***
**/

.highcharts-credits {
    display: none !important;
}

.highcharts-background {
    fill: transparent !important;
}

.highcharts-exporting-group {
    display: none !important;
}

.legend-symbol {
    width: 60px;
    height: 24px;
    border-radius: 20px;
    border: 1px solid;
    text-align: center;
    line-height: 24px;
    font-size: 12px;
}

.legend-text {
    width: 70px;
    text-align: center;
    font-size: 8px;
    font-family: Roboto;
    margin-top: 5px;
    fill: var(--secondary-text) !important;
    color: var(--secondary-text) !important;
}

::ng-deep.highcharts-legend-item {
    // fill: var(--secondary-text) !important;
    // color: var(--secondary-text) !important;
}

::ng-deep.highcharts-legend-item:hover text {
    // fill: inherit !important; /* Maintain the original color */
}

::ng-deep.highcharts-text-outline {
    fill: var(--secondary-text) !important;
    stroke: var(--secondary-text) !important;
}

/**
*** Highcahrts - end ***
**/
/* 
** Lib customization - end **
*/

/* 
** Global Colors **
*/
$colors: (
    "very-good": var(--very-good),
    "good": var(--good),
    "fair": var(--fair),
    "poor": var(--poor),
    "very-poor": var(--very-poor),
    "unknown": var(--unknown),
    "green": var(--very-good),
    "red": var(--very-poor),
);

@each $color, $value in $colors {
    [class*="#{$color}-color"] {
        color: $value !important;
    }

    [class*="#{$color}-border"] {
        border-color: $value !important;
    }

    [class*="#{$color}-background"] {
        background-color: $value !important;
    }
}

/* 
** Global Colors - end**
*/

/* Global style*/
img {
    max-width: 100%;
}

.pointer {
    cursor: pointer;
}

.right-slide-out-panel {
    padding: 30px;

    h5 {
        font-size: 24px;
        color: #7e7e7e;
        margin-bottom: 30px;
    }

    .form-field {
        mat-label {
            display: block;
            font-size: 16px;
            color: #7e7e7e;
        }
    }
}

.slide-out-panel-dialog {
    .mat-mdc-form-field {
        width: 100%;
    }
}

.filter {
    .add-new-button {
        position: relative;
        float: right;
    }

    .search-form-field {
        width: 300px;
    }
}

// .form-inline {
//     .form-field {
//         mat-label {
//             display: inline-block;
//             width: 150px;
//             font-size: 16px;
//         }

//         .mat-mdc-form-field {
//             width: 300px;
//         }
//     }
// }


// .form-field {
//     .mat-mdc-form-field.no-label {
//         width: 100%;
//     }

//     .mat-mdc-icon-button.mat-mdc-button-base {
//         padding: 0;
//         width: auto;
//         height: auto;
//     }
// }

.form-field.search {
    .mat-mdc-form-field {
        width: 100%;

        input.mat-mdc-input-element::placeholder {
            color: #c1c1c1;
        }

        ::ng-deep.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
            border-bottom-color: #008cd7;
        }
    }
}

.mat-mdc-form-field {

    input.mat-mdc-input-element::placeholder,
    input::placeholder {
        color: #c1c1c1 !important;
    }
}

.form-field {
    mat-label {
        color: #7e7e7e !important;
    }

    input[readonly="true"] {
        color: #7e7e7e !important;
    }

    .mat-mdc-form-field {
        .mat-mdc-icon-button {
            padding-right: 0 !important
        }

        .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
            .mat-mdc-form-field-error-wrapper {
                Padding-left: 0 !important;
            }
        }
    }

    &.block-field {
        margin-bottom: 10px;

        mat-label {
            display: block;
            line-height: 24px;
        }

        .mat-mdc-form-field.w-90 {
            width: 90%
        }

        .mat-mdc-form-field.w-95 {
            width: 95%
        }
    }

    &.inline-field {
        mat-label {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
        }

        .mat-mdc-form-field {
            display: inline-block;
        }

        .mat-mdc-radio-group {
            display: inline-block;
        }
    }

    &.date-time-field {
        .mat-mdc-form-field.date-field {
            display: inline-block;
            width: 43%;
            margin-right: 2%;
        }

        .mat-mdc-form-field.time-field {
            display: inline-block;
            width: 45%;
        }
    }

    mat-label.required::after {
        content: ' *';
        color: #dc272d; // Optional: Change the color of the asterisk
    }

    .filter-icon-field {
        .mat-mdc-select-panel {
            .mat-mdc-optgroup {
                .mat-mdc-optgroup-label {
                    background-color: #d9d9d9;
                }
            }
        }

        .mat-mdc-form-field-infix {
            width: fit-content !important;
        }

        .mdc-line-ripple {
            display: none;
        }
    }
}

.ptwLink {
    color: var(--highlight-text) !important;
}

.assets-table {
    .mat-mdc-table {
        .mat-column-action {
            width: 250px;
        }
    }
}

.details-info-field {
    margin: 15px 0;

    label,
    .label {
        color: var(--secondary-text);
        width: 160px;
    }

    span {
        color: var(--primary-text);
    }

    .value {
        font-weight: 700;
    }
}

.loader,
.no-data-available {
    color: var(--primary-text);
}

.dark {
    .matrix-container {

        .legend,
        .item-description {
            color: #c1c1c1 !important;
        }

        .yAxis,
        .xAxis {
            color: #fff !important;
        }
    }

    .legend {
        color: #c1c1c1 !important;
    }

    // ::ng-deep.highcharts-legend-item{
    //     fill: #c1c1c1 !important;
    //     color: #c1c1c1 !important;
    // }
}

::ng-deep.mat-mdc-optgroup-label {
    background-color: #9d9d9d !important;
}


.mat-mdc-radio-button .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
.mat-mdc-radio-button .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: #005c8e !important;
}

.fa.fa-check {
    color: #00943d !important;
}

.fa-solid.fa-xmark {
    color: #dc272d !important;
}

.page-title,
.section-title {
    color: var(--highlight-text) !important;
    font-weight: bold;
}

.back-navigation {
    color: var(--highlight-text) !important;
    cursor: pointer;
    text-decoration: underline;
}

// .whats-new-container, .help-container{
//     border: 1px solid var(--highlight-text);
//     background-color: #fff;
//     height:100%
// }


/****
** Overlay panels
*/

.cdk-overlay-container {
    .cdk-overlay-pane {
        div.mat-mdc-select-panel {
            overflow-x: hidden;
        }

        .mat-mdc-menu-panel {
            box-shadow: none;
            border-bottom-left-radius: 10%;

            .mat-mdc-menu-content {
                padding: 0;

                .mat-mdc-menu-item {
                    background: var(--brand-bg);
                    color: #fff !important;
                }

                .mat-mdc-menu-item:not(:last-child) {
                    border-bottom: 1px solid #c1c1c1;
                }
            }
        }
    }

    &.light {
        div.mat-mdc-select-panel {
            overflow-x: hidden;
            background-color: #c1c1c1;
        }
    }

    &.dark {
        div.mat-mdc-select-panel {
            overflow-x: hidden;
            background-color: #3c3c3c;
        }
    }

    .mat-mdc-option:focus.mdc-list-item,
    .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
        background-color: #005c8e !important;
        color: #fff !important;

        .mdc-list-item__primary-text {
            color: #fff !important;
        }
    }

    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
        color: #fff !important;
    }

    .mat-mdc-tab-body-wrappe {
        height: auto !important;
    }

    .mat-mdc-tab-body-wrapper {
        height: 100% !important;
    }
}

/*** 
**  Mat selection List
*/

::ng-deep.mat-selection-list {
    // .mat-mdc-list-item.mat-mdc-list-option[aria-selected="true"]{
    //     background-color: var(--brand-bg) !important;
    //     .mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text{
    //     color: #fff !important;
    //     }
    // }

}

::ng-deep.mdc-list-item__primary-text {
    font-size: 14px !important;
    color: var(--highlight-text) !important;
}